<div class="overflow-x-auto">
  <div class="flex items-center btn-itax">
    <router-link :to="{ name: 'listMailBox' }" class="mr-3">
      <el-button size="mini">
        <img src="@/assets/icons/icon_arrow_left.svg" alt="" class="h-4 w-4" />
      </el-button>
    </router-link>

    <el-button @click="getDetailMailBox()" size="mini">
      <img src="@/assets/icons/icon_sync.svg" alt="" class="h-4 w-4" />
    </el-button>

    <div class="ml-3">
      <loading-invoice
        v-if="isUpdateDetailMailBox"
        textContent="Hệ thống đang xử lý mail..."
      />
    </div>
  </div>

  <div class="minWidthLg bg-white mt-3 p-5">
    <div class="user-info flex mt-3">
      <img src="@/assets/user.svg" alt="photo" class="w-32 h-32" />
      <table class="w-full table ml-5">
        <tbody>
          <tr>
            <td class="w-24">Tiêu đề:</td>
            <td>{{detailMail.subject}}</td>
          </tr>
          <tr>
            <td class="w-24">Người gửi:</td>
            <td>{{detailMail.sender}}</td>
          </tr>
          <tr>
            <td class="w-24">Thời gian:</td>
            <td>
              {{ formatDate(detailMail.received_time) + " " +
              formatTime(detailMail.received_time)}}
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="mt-10 font-normal text-sm">
      <div v-html="escapeStyleTag(detailMail.content)"> </div>
      <hr class="my-2">
      <ul>
        <li v-for="(item, index) in detailMail.files" :key="index">
          <span
            @click="downloadfile(item)"
            class="cursor-pointer hover:text-primary"
          >
            {{ formatFileName(item) }}
          </span>
        </li>
      </ul>
    </div>
  </div>
</div>
