import { URL } from "@/api/mailbox";
import formatDate from "@/plugins/formatDate.js";
import formatFileName from "@/plugins/formatFileName.js";
import formatTime from "@/plugins/formatTime.js";
import loadingInvoice from "@/components/loadingInvoice";
import { TIME_INTERVAL } from "@/consts";

export default {
  components: {
    loadingInvoice,
  },
  data() {
    return {
      idMailBox: null,
      detailMail: [],
      isUpdateDetailMailBox: false,
      idInterval: null,
    };
  },

  created() {
    this.idMailBox = this.$route.params.mailId;
    this.getDetailMailBox();
  },

  methods: {
    async getDetailMailBox() {
      const result = await this.$request({
        url: URL.DETAIL_MAILBOX.replace(":id", this.idMailBox),
      });
      const res = result.data;

      if (res.code == 200) {
        this.detailMail = res.data;
        this.isUpdateDetailMailBox = res.data.is_updating;
      }
    },

    async downloadfile(item) {
      const params = {};
      params.download = true;
      const result = await this.$request({
        baseURL: `${process.env.VUE_APP_BACK_END_URL}/files/`,
        responseType: "blob",
        url: item,
        params,
      });
      this.downloadFile(result.data, item);
    },

    downloadFile(blob, fileName) {
      var a = document.createElement("a");
      document.body.appendChild(a);
      a.style = "display: none";
      let url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = this.formatFileName(fileName);
      a.click();
      window.URL.revokeObjectURL(url);
    },

    escapeStyleTag(html) {
      return (html || "").replace(/(<style[\w\W]+style>)/g, "")
    },

    formatTime,
    formatFileName,
    formatDate,
  },

  watch: {
    isUpdateDetailMailBox(newVal) {
      if (newVal) {
        clearInterval(this.idInterval);
        this.idInterval = setInterval(() => {
          this.getDetailMailBox();
        }, TIME_INTERVAL);
      } else {
        clearInterval(this.idInterval);
      }
    },

    async $route() {
      this.isUpdateDetailMailBox = false;
    },
  },
};
